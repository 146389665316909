import styled from "styled-components";
import { Link } from "react-router-dom";
import ErrorImg from "../../assets/image/404Error-2.png";

export function NotFound() {
  return (
    <NotFountStyles>
      <div className="notFound-conatiner">
        <div className="notFound_info">
          <h2>Error 404 </h2>
          <p>
            No se encontro la pagina. Puede regresar al inicio y colocar su ID
            de aliado.
          </p>
          <p>{/* <strong>Total COP: </strong> */}</p>
        </div>
        <Link to="/">
          <button className="recibo__btn-inicio ">Inicio</button>
        </Link>
      </div>
      <div className="notFound__img">
        <img src={ErrorImg} alt="" />
      </div>
    </NotFountStyles>
  );
}

const NotFountStyles = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  p,
  strong {
    color: #828282;
  }
  .notFound-conatiner {
    padding: 2rem;
    width: 90%;
    /* box-shadow: 0px 32px 50px rgba(92, 92, 92, 0.16);
    border-radius: 15px; */
    display: flex;
    flex-direction: column;
    gap: 2rem;
    h2 {
      font-size: 4rem;
      color: #00943d;
      margin-bottom: 1rem;
    }
  }
  .recibo__btn-inicio {
    font-family: var(--body-font);
    font-size: 16px;

    margin-top: 2rem;
    border: none;
    padding: 16px 60px;
    background: #00943d;
    color: white;
    border-radius: 15px;
    transition: 1s;
    :hover {
      background-color: #72b872;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      transition: 1s;
    }
  }
  .notFound__img {
    width: 30%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (min-width: 960px) {
    .notFound-conatiner {
      width: 500px;
      min-width: 500px;
    }
  }
`;
