import styled from "styled-components";
export const AliadosLoginStyles = styled.div`
  /* width: 100%; */
  padding-top: 8rem;
  /* display: flex;
  align-items: center; */
  margin-bottom: 8rem;
  height: 100vh;
  .login__slider {
    display: none;
  }
  @media screen and (min-width: 960px) {
    display: flex;
    justify-content: center;
    align-items: center;
    .lodin-container {
      display: flex;
      width: 100%;
      gap: 2rem;
      justify-content: space-between;
      max-width: 1300px;
    }

    .login__slider {
      display: initial;
    }
  }
`;
