import React, { useEffect, useContext } from "react";
import { AliadosContext } from "../context/AliadosContext";
import styled from "styled-components";

const FORM_ID = "payment-form";

function addCheckout(preferenceId) {
  const mp = new window.MercadoPago(process.env.REACT_APP_MERCADOPAGO_KEY,
    {
      locale: "es-CO",
    }
  );

  // Inicializa el checkout
  mp.checkout({
    preference: {
      id: preferenceId,
    },
    render: {
      container: `#${FORM_ID}`, // Indica el nombre de la clase donde se mostrará el botón de pago
      label: "Pagar", // Cambia el texto del botón de pago (opcional)
    },
  });

  console.log("Este es el de mercado: " + preferenceId);
}

export function MercadoPago() {
  //CONTEXTS: --------------------------------
  const { pagoTotal, aliado, preferenceId } = useContext(AliadosContext);
  const aliadoData = aliado;

  useEffect(() => {
    if (preferenceId) {
      // con el preferenceId en mano, inyectamos el script de mercadoPago
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://sdk.mercadopago.com/js/v2";
      script.addEventListener("load", addCheckout(preferenceId)); // Cuando cargue el script, se ejecutará la función addCheckout
      document.body.appendChild(script);
    }
  }, []);

  return (

    <FromMPStyles>
      <div className="resumen-conatiner">
        <div className="resumen_info">
          <h2>Confirmación de pago</h2>
          <p><strong>Sucursal: </strong>  {`${aliadoData.marca}`}</p>
          <p><strong>Total COP: </strong> $ {pagoTotal}</p>
        </div>
        <div id={FORM_ID} method="GET"
        >
          <div />
        </div>
      </div>



    </FromMPStyles>
  );
}

const FromMPStyles = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  p,strong {
    color: #828282
  }
  .resumen-conatiner {
    padding: 2rem;
    width: 90%;
    box-shadow: 0px 32px 50px rgba(92, 92, 92, 0.16);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    h2 {
      color:  #00943d;
      margin-bottom: 1rem;
    }
  }
  @media screen and (min-width: 960px) {
    .resumen-conatiner {
      width: 500px;
      min-width: 500px;
    }
  }
`;
