import styled from "styled-components";
export const PageCheckoutStyles = styled.div`
  padding-top: 8rem;
  /* display: flex;
  align-items: center; */
  margin-bottom: 8rem;
  /* height: 100vh; */

  .checkout-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .checkput__Card-product-info {
    display: flex;
    gap: 2rem;
    margin-top: 1rem;
    
    flex-direction: column;
   p {
     color: #828282;
   }


  }
  .checkput__Card-product-fechas {
   p {
     color: #828282;
   }
  }
  .checkput__Card-product-date {
    margin: 1rem 0;
      .checkput__success {
      padding: 1rem;
      background-color: #02ba4d;
      border-radius: 15px;
      display: flex;
      gap: 2rem;
      align-items: center;
      justify-content: center;
      p {
        font-size:14px;
        color: white;
      }
    }
      .checkput__nopay {
      padding: 1rem;
      background-color: #F33030;
      border-radius: 15px;
      display: flex;
      gap: 2rem;
      align-items: center;
      justify-content: center;
      p {
        font-size:14px;
        color: white;
      }
    }

  }

  .checkout__card {
    padding: 2rem;
    width: 90%;
    box-shadow: 0px 32px 50px rgba(92, 92, 92, 0.16);
    border-radius: 15px;
    
    .checkput__card-branding {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1.2rem;
      .checkput__branding-img {
        background: red;
        width: 76px;
        height: 78px;
        border-radius: 80px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      h3 {
        color: var(--main-color);
      }
    }
    .checkput__card-product {
      height: auto;
      h3 {
        text-align: left;
        color: #828282;
      }
      .checkput__product-items {
        z-index: -1000;
        height: 100%;
        /* background-color: #c4c4c4; */
        border-radius: 15px;
        margin-top: 1rem;
      }
    }
    .checkput__labels {
      margin-top: 4rem;
      .checkput__labels-ordenes {
        padding: 16px 32px;
        display: flex;
        justify-content: space-between;
        background: #1babe9;
        border-radius: 15px;
        color: white;
        font-weight: 600;
      }
      .checkput__labels-total {
        margin-top: 1rem;
        padding: 16px 32px;
        display: flex;
        justify-content: space-between;
        background: #ffe572;
        border-radius: 15px;
        color: #828282;
        font-weight: 600;
      }
      a {
        font-size: 14px;
        color: #b8b8b8;
      }
    }
  }
  .checkput__buttonPay {
    font-family: var(--body-font);
    font-size: 16px;

    margin-top: 2rem;
    border: none;
    padding: 16px 60px;
    background: #00943d;
    color: white;
    border-radius: 15px;
    transition: 1s;
    :hover {
      background-color: #72b872;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      transition: 1s;
    }
  }
  @media screen and (min-width: 960px) {
    .checkput__Card-product-info {
      flex-direction: row;
      align-items: center;
    }
    .checkout__card {
      display: flex;
      /* gap: 2rem; */
    }
    .checkout__card-info {
      width: 50%;
      display: flex;
      flex-direction: column;
      .checkput__labels {
        margin-top: 5rem;
        display: flex;
        gap: 10%;
        .checkput__labels-ordenes {
          width: 50%;
        }
        .checkput__labels-total {
          width: 50%;
          margin-top: 0;
        }
      }
    }
    .checkput__boxButton {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      .checkput__buttonPay {
        align-self: flex-end;
      }
    }
  }
`;
