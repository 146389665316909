import React from "react";
import ReactDOM from "react-dom";
import "./styles/GlobalStyles.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

// eslint-disable-next-line
// window.mercadopago = new MercadoPago(
//   "TEST-357d6700-c2fb-4ee7-9884-af7ad83740e3",
//   {
//     locale: "es-AR",
//   }
// );

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById("root")
);
