import { CardSliderStyles } from "./CardSliderStyles";
import imgSlider from "../../assets/image/brett-jordan-phUtWl8RyFE-unsplash.jpg";

export function CardSlider() {
  return (
    <CardSliderStyles>
      <div className="slider-img">
        <img src={imgSlider} alt="" />
      </div>
    </CardSliderStyles>
  );
}
