import styled from "styled-components";

export const CardSliderStyles = styled.div`
  .slider-img {
    width: 671px;
    height: 561px;
    border-radius: 30px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
