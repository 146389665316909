/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import adomibrand from "../assets/logo/adomiIsotipo.png";
import { AliadosContext } from "./AliadosContext";
import dayjs from "dayjs";

export const AliadosState = (props) => {
  const [aliado, setAliado] = useState({
    id: "",
    img: adomibrand,
    name: "",
    marca: ""
  });
  const [preferenceId, setPreferenceId] = useState(null);
  const [errLoging, setErrLoging] = useState(false);
  const [dataOrder, setDataOrder] = useState([]);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [pagoTotal, setPagoTotal] = useState(0);
  const [checkActive, setCheckActive] = useState(false);
  const [billingCodeId, setBillingCodeId] = useState("");
  const [payDisabled, setPayDisabled] = useState(false);
  // TODO: TEMPORAL
  const [initialPay, setInitialPay] = useState(true);

  useEffect(() => {
    // const dayDate = dayjs().date()

    if (initialPay) {

      //Se formatea para poder mandar como queryParams al endpoint -----------------------------------------------------------
      const fromDate = dayjs().startOf('year').format('YYYY-MM-DD');
      setDateFrom(fromDate);
      const toDate = dayjs().format('YYYY-MM-DD');
      setDateTo(toDate);
    }
    // else {
    //   if (dayDate >= 15) {
    //     const fromDate = dayjs().startOf('month').format('YYYY-MM-DD');
    //     const fromDateForm = dayjs().startOf('month').format('DD-MM-YYYY');
    //     setDateFromView(fromDateForm);
    //     setDateFrom(fromDate);
    //     const toDate = dayjs().startOf('month').add(14, 'day').format('YYYY-MM-DD');
    //     const toDateForm = dayjs().startOf('month').add(14, 'day').format('DD-MM-YYYY');
    //     setDateToView(toDateForm);
    //     setDateTo(toDate);
    //   } else {
    //     const fromDate = dayjs().subtract(1, 'month').startOf('month').add(15, 'day').format('YYYY-MM-DD');
    //     const fromDateForm = dayjs().subtract(1, 'month').startOf('month').add(15, 'day').format('DD-MM-YYYY');
    //     setDateFromView(fromDateForm)
    //     setDateFrom(fromDate)
    //     const toDate = dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
    //     const toDateForm = dayjs().subtract(1, 'month').endOf('month').format('DD-MM-YYYY');
    //     setDateToView(toDateForm)
    //     setDateTo(toDate)
    //   }
    // }

    const getAliado = (billingCode) => {
      const API_URL =
        "https://us-central1-adomi-dev.cloudfunctions.net/core/api/v1/branch-offices/by-billing-code/" +
        billingCode;
      axios.get(API_URL)
        .then(function (res) {
          setAliado({
            ...aliado,
            img: res.data.data.brand.logo,
            marca: res.data.data.brand.name,
            name: res.data.data.name,
            id: res.data.data.id,
          })

          if (res?.data.code !== 200) {
            setErrLoging(true);
          }

          const URL = `https://us-central1-adomi-dev.cloudfunctions.net/OrderMs/me/common/monetization-report?branchOfficeId=${res.data.data.id}&fromDate=${dateFrom}&toDate=${dateTo}`
          console.log(URL);
          axios.get(URL, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "x-api-key": process.env.REACT_APP_ORDER_KEY,
            },
          })
            .then(function (response) {
              const data = response.data.data;
              setDataOrder(data)
              if (data.length === 0) {
                setCheckActive(true);
              }

              let total = 0;
              for (const order of data) {
                total += order.transaction_cost;
              }

              if (data.length > 0 && total < 1000) {
                setPayDisabled(true)
              }
            })
            .catch(function () {
              alert("Unexpected error");
            });
        })
    };

    getAliado(billingCodeId);

  }, [billingCodeId, dateFrom, dateTo]);


  return (
    <AliadosContext.Provider
      value={{
        setDateFrom,
        setDateTo,
        initialPay,
        setInitialPay,
        payDisabled,
        setBillingCodeId,
        checkActive,
        pagoTotal,
        setPagoTotal,
        dateFrom,
        dateTo,
        dataOrder,
        errLoging,
        aliado,
        preferenceId,
        setPreferenceId
      }}
    >
      {props.children}
    </AliadosContext.Provider>
  );
};
