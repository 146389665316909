/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { CgMenu, CgClose } from "react-icons/cg";
import { Link } from "react-router-dom";
import logoNav from "../../assets/logo/image 5.png";
import { NavbarStyles } from "./NavbarStyles";

export function Navbar() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [navbar, setNavbar] = useState(false);

  return (
    <div>
      <NavbarStyles>
        <nav className={navbar ? "navbar active-nav" : "navbar"}>
          <div className="wrapper bd-container">
            <Link to="/">
              <div data-aos="fade" className="nav__logo">
                <a to="/">
                  <img src={logoNav} alt="" />
                </a>
              </div>
            </Link>

            <div
              className="nav__icon-menu iconSize"
              onClick={() => setShowMobileMenu(!showMobileMenu)}
            >
              {showMobileMenu ? <CgClose /> : <CgMenu />}
            </div>

            <ul
              className={
                !showMobileMenu ? "nav__menu " : "nav__menu open__menu"
              }
            >
              <div className="nav__header-mobile">
                <div
                  className="nav__icon-close iconSize"
                  onClick={() => setShowMobileMenu(!showMobileMenu)}
                >
                  <CgClose />
                </div>
                <div data-aos="fade" className="nav__logo-mobile">
                  <a href="#">
                    <img src={logoNav} alt="" />
                  </a>
                </div>
              </div>

              <li className="nav__item">
                <a
                  href="#"
                  className="nav__link"
                  onClick={() => setShowMobileMenu(!showMobileMenu)}
                >
                  Plataforma
                </a>
              </li>
              <li className="nav__item">
                <a
                  href="#"
                  className="nav__link"
                  onClick={() => setShowMobileMenu(!showMobileMenu)}
                >
                  Nosotros
                </a>
              </li>
              <li className="nav__item">
                <a
                  href="#"
                  className="nav__link"
                  onClick={() => setShowMobileMenu(!showMobileMenu)}
                >
                  Terminos y condiciones
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </NavbarStyles>
    </div>
  );
}
