import { Footer } from "./components/Footer/Footer";
import { Routes, Route } from "react-router-dom";
import { Navbar } from "./components/Navbar/Navbar";
import { AliadosLogin } from "./page/AliadosLogin/AliadosLogin";
import { PageCheckout } from "./page/PageCheckout/PageCheckout";
import { MercadoPago } from "./utils/MercadoPago";
// CONTEXTS --------------------------------
import { AliadosState } from "./context/AliadosState";
import { ReciboAliado } from "./page/ReciboAliado/ReciboAliado";
import { NotFound } from "./page/NotFound/NotFound";

function App() {

  return (
    <>
      <Navbar />
      <AliadosState>
        <Routes>
          <Route path='*' element={<NotFound />} />
          <Route path="/" exact element={<AliadosLogin />} />
          <Route path="/recibo/:preferenceId" exact element={<ReciboAliado />} />
          <Route path="/checkout/:billingCode" element={<PageCheckout />} />
          <Route path="/mercadopago" element={<MercadoPago />} />
        </Routes>
      </AliadosState>
      <Footer />
    </>
  );
}

export default App;

