/* eslint-disable react-hooks/exhaustive-deps */
import styled from "styled-components";
import html2canvas from "html2canvas";
import { useParams } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import logoNav from "../../assets/logo/image 5.png";
import { jsPDF } from "jspdf";
import { Link } from "react-router-dom";

export function ReciboAliado() {
  const [commissionPayment, setCommissionPayment] = useState({});
  const { preferenceId } = useParams();

  const onClickExportar = function () {
    html2canvas(document.querySelector("#factura")).then((canvas) => {
      var img = canvas.toDataURL("image/png");
      var doc = new jsPDF("p", "mm");
      doc.addImage(img, "PNG", 10, 10);
      doc.save(`comprobante-de-pago-adomi_${preferenceId}.pdf`);
    });
  };

  const getPaymentData = async () => {
    const data = await axios.get(
      `https://us-central1-adomi-dev.cloudfunctions.net/core/api/v1/commission-payments/by-preference/${preferenceId}`,
      {
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_ORDER_KEY,
        },
      }
    );
    setCommissionPayment(data.data.data);
  };

  const formatDate = (date) => {
    if (!date) return "";
    return dayjs(date).format("YYYY-MM-DD.  h:mm A");
  };

  const translateStatus = (status) => {
    if (!status) return "";
    const translation = {
      approved: "Aprobado",
      pending: "Pendiente",
      cancelled: "Cancelado",
      rejected: "Rechazado",
    };
    return translation[status];
  };

  useEffect(async () => {
    if (preferenceId) {
      await getPaymentData();
    }
  }, [preferenceId]);

  return (
    <ReciboPage>
      <div className="card-recibo">
        <div className="card-container" id="factura">
          <h2>Recibo de pago</h2>
          <div className="card-infoPago">
            <p>
              <b>Ref. pago: </b> {commissionPayment?.preference_id}
            </p>
            <p>
              <b>Fecha: </b> {formatDate(commissionPayment?.created_at)}
            </p>
            <p>
              <b>Sucursal: </b> {commissionPayment?.branch_office?.name}
            </p>
            <p>
              <b>Pago total: </b> COP{" "}
              {commissionPayment?.preference?.items[0].quantity *
                commissionPayment?.preference?.items[0].unit_price}
            </p>
            <p>
              <b>Estado: </b> {translateStatus(commissionPayment?.status)}
            </p>
          </div>
          <div className="card__box-logo">
            <div className="card-logo">
              <img src={logoNav} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="rebibo__box-btn">
        <button
          onClick={() => onClickExportar()}
          className="recibo__btn .cho-container"
        >
          Descargar
        </button>
        <Link to="/">
          <button className="recibo__btn-inicio ">Inicio</button>
        </Link>
      </div>
    </ReciboPage>
  );
}

const ReciboPage = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3rem;
  p,
  strong {
    color: #828282;
  }
  .card__box-logo {
    display: flex;
    justify-content: flex-end;
    .card-logo {
      width: 120px;
      height: 60px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .card-recibo {
    /* padding: 3rem 4rem; */
    width: 90%;
    box-shadow: 0px 32px 50px rgba(92, 92, 92, 0.16);
    border-radius: 15px;
    /* display: flex;
    flex-direction: column; */
    gap: 1rem;
    .card-container {
      padding: 3rem 4rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    h2 {
      color: #00943d;
      margin-bottom: 0.2rem;
      border-bottom: #dadada 1px dotted;
      padding-bottom: 1rem;
    }
  }
  .rebibo__box-btn {
    display: flex;
    gap: 2rem;
    .recibo__btn {
      font-family: var(--body-font);
      font-size: 16px;

      margin-top: 2rem;
      border: none;
      padding: 16px 60px;
      background: #f2c94c;
      color: white;
      border-radius: 15px;
      transition: 1s;
      :hover {
        background-color: #f6d87c;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        transition: 1s;
      }
    }
    .recibo__btn-inicio {
      font-family: var(--body-font);
      font-size: 16px;

      margin-top: 2rem;
      border: none;
      padding: 16px 60px;
      background: #00943d;
      color: white;
      border-radius: 15px;
      transition: 1s;
      :hover {
        background-color: #72b872;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        transition: 1s;
      }
    }
  }

  @media screen and (min-width: 960px) {
    .card-recibo {
      width: 700px;
      max-width: 700px;
    }
  }
`;
