import { useEffect, useRef, useContext } from "react";
import { PageCheckoutStyles } from "./PageCheckoutStyles";
import Aos from "aos";
import "aos/dist/aos.css";
import MaterialTable from "material-table";
import { AliadosContext } from "../../context/AliadosContext";
import { useNavigate, useParams } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import "./PageCheckout.css";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";

import dayjs from "dayjs";

export function PageCheckout() {
  // Animacion Aos settting: -------------------------------------------------------------
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const priceRef = useRef();
  const quantity = useRef();

  const { billingCode } = useParams();

  //CONTEXTS: --------------------------------
  const {
    setDateFrom,
    setDateTo,
    setInitialPay,
    initialPay,
    payDisabled,
    setBillingCodeId,
    checkActive,
    setPagoTotal,
    dateFrom,
    dateTo,
    dataOrder,
    aliado,
    setPreferenceId,
  } = useContext(AliadosContext);
  const aliadoData = aliado;

  const formatDate = (date) => {
    if (!date) return "";
    const result = dayjs(date).format("YYYY-MM-DD.  h:mm A");
    return result;
  };

  let total = 0;
  for (const order of dataOrder) {
    total += order.transaction_cost;
    order.created_at = formatDate(order.created_at);
  }
  const totalTransaction_cost = new Intl.NumberFormat().format(total);
  setPagoTotal(totalTransaction_cost);

  useEffect(() => {
    setBillingCodeId(billingCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useNavigate();

  const onClickCheckout = () => {
    const orderData = {
      price: total,
      quantity: 1,
      description: "Precio total de ordenes a pagar",
      branch_office_id: aliadoData.id,
      from_date: dateFrom,
      to_date: dateTo,
    };

    const API_URL =
      "https://us-central1-adomi-dev.cloudfunctions.net/core/api/v1/mercadopago/create_preference";

    fetch(API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_ORDER_KEY,
      },
      body: JSON.stringify(orderData),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (preference) {
        setPreferenceId(preference.data.id);
        history("/mercadopago");
      })
      .catch(function () {
        alert("Unexpected error");
      });
  };

  // Se setea las columnas de la tabla --------------------------------

  const columnas = [
    {
      title: "Fecha y Hora",
      field: "created_at",
    },
    {
      title: "Referecia",
      field: "reference",
    },
    {
      title: "Costo de transación",
      field: "transaction_cost",
      type: "numeric",
    },
  ];

  // funciones para capturar el valor del datePicker --------------------------------

  const handleChangeDatefrom = (newValue) => {
    setInitialPay(false);
    const date = dayjs(newValue?.value).format("YYYY-MM-DD");
    setDateFrom(date);
  };
  const handleChangeToDate = (newValue) => {
    setInitialPay(false);
    const date = dayjs(newValue?.value).format("YYYY-MM-DD");
    setDateTo(date);
  };

  return (
    <PageCheckoutStyles>
      <main className="checkout-container">
        <section className="checkout__card bd-container" data-aos="fade">
          <div className="checkout__card-info">
            <div className="checkput__card-branding">
              <figure className="checkput__branding-img">
                <img src={aliadoData.img} alt="" />
              </figure>
              <h3 data-aos="fade">{`${aliadoData.marca}`}</h3>
            </div>
            <div className="checkput__card-product">
              <div className="checkput__Card-product-info">
                <h3>Ordenes</h3>
              </div>
              <div className="checkput__Card-product-fechas">
                <p>
                  <strong>Desde: </strong>{" "}
                  <DatePickerComponent
                    format="yyyy-MM-dd"
                    placeholder="Enter Date"
                    value={dateFrom}
                    onChange={handleChangeDatefrom}
                  ></DatePickerComponent>
                  {initialPay ? (
                    <strong style={{ color: "red" }}>(INICIO DE AÑO)</strong>
                  ) : (
                    <div></div>
                  )}
                </p>
                <p>
                  <strong>Hasta: </strong>
                  <DatePickerComponent
                    format="yyyy-MM-dd"
                    placeholder="Enter Date"
                    value={dateTo}
                    onChange={handleChangeToDate}
                  ></DatePickerComponent>
                </p>
              </div>
              <div className="checkput__Card-product-date">
                {checkActive ? (
                  <div className="checkput__success">
                    {" "}
                    <FaCheck />
                    <p>No hay ordenes a pagar</p>
                  </div>
                ) : (
                  <div />
                )}
                {payDisabled ? (
                  <div className="checkput__nopay">
                    {" "}
                    x<p>Monto insuficiente para pagar</p>
                  </div>
                ) : (
                  <div />
                )}
              </div>

              <div className="checkput__product-items">
                <MaterialTable
                  title="Informacion de ordenes"
                  columns={columnas}
                  data={dataOrder}
                />
              </div>
            </div>
            <div className="checkput__labels">
              <div className="checkput__labels-ordenes">
                <p>Total ordenes:</p> <p ref={quantity}>{dataOrder.length}</p>
              </div>
              <div className="checkput__labels-total">
                <p>Total a pagar:</p>{" "}
                <p ref={priceRef}>COP {totalTransaction_cost}</p>
              </div>
              {/* <a href="">¿Necesita ayuda con algo?</a> */}
            </div>
          </div>
          <div className="checkput__boxButton">
            {checkActive || payDisabled ? (
              <div />
            ) : (
              <button
                id="checkout-btn"
                onClick={onClickCheckout}
                className="checkput__buttonPay .cho-container"
              >
                Pagar
              </button>
            )}
          </div>
        </section>
      </main>
    </PageCheckoutStyles>
  );
}
