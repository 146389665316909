import styled from "styled-components";
export const CardFormStyles = styled.section`
  .cardform-container {
    margin: 0 auto;
    width: 85%;
    /* height: 561px; */
    border-radius: 15px;
    box-shadow: 0px 32px 50px rgba(92, 92, 92, 0.16);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    padding: 3rem 2rem;
    a {
      margin-top: 1.5rem;
      font-size: 14px;
      color: #b8b8b8;
    }
  }
  .cardform-title {
    color: var(--main-color);
    width: 100%;
    text-align: left;
    margin-bottom: 2.5rem;
    font-size: 1.5rem;
  }
  .cardform__input {
    width: 100%;
    padding: 1rem 2rem;
    color: var(--grey);
    border: 1px solid var(--grey);
    border-radius: 15px;
    text-align: left;
    font-family: var(--body-font);
    font-size: 1.2rem;
  }

  .cardfrom__bottom {
    margin-top: 1.5rem;
    width: 100%;
    border: none;
    padding: 1rem 2rem;
    background-color: var(--main-color);
    color: white;
    border-radius: 15px;
    font-family: var(--body-font);
    font-size: 1.2rem;
    transition: 1s;
    :hover {
      background-color: #72b872;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      transition: 1s;
    }
  }

  @media screen and (min-width: 960px) {
    .cardform-container {
      width: 400px;
      height: 398px;
    }
  }
`;
