/* eslint-disable jsx-a11y/anchor-is-valid */
import { CardFormStyles } from "./CardFormStyles";
import { Formik } from "formik";
// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect, useContext } from "react";

import { AliadosContext } from "../../context/AliadosContext";

export function CardForm() {
  // Animacion Aos settting:
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const { getAliado, setBillingCodeId } = useContext(AliadosContext);

  const history = useNavigate();

  return (
    <CardFormStyles>
      <Formik
        initialValues={{
          id: "",
        }}
        onSubmit={(valores) => {
          // getAliado(valores.id);
          history(`/checkout/${valores.id}`);
        }}
      >
        {({ values, handleSubmit, handleChange, handleBlur }) => (
          <form className="cardform-container" onSubmit={handleSubmit}>
            <div className="cardform-title">
              <h2 data-aos="fade-up">Bienvenido</h2>
            </div>

            <input
              className="cardform__input"
              type="text"
              name="id"
              placeholder="ID Sucursal"
              value={values.id}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />

            <button type="submit" className="cardfrom__bottom">
              Entrar
            </button>

            {/* <a href="">¿No sabes cual es tu ID de aliados?</a> */}
          </form>
        )}
      </Formik>
    </CardFormStyles>
  );
}
