/* eslint-disable jsx-a11y/anchor-is-valid */
import { FooterStyles } from "./FooterStyles";

export function Footer() {
  return (
    <FooterStyles>
      <div className="footer__container bd-container">
        {/* <div className="footer__logo">
          <img src={footerLogo} alt="Logo fuente de poder " />
        </div> */}
        <div className="footer__nav">
          <p className="footer__nav-title">Funciones</p>
          <ul>
            <li>
              <a>Plataforma</a>
            </li>
            <li>
              <a>Terminos y condiciones</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer__copy">
        <p>&#169; 2022 Adomy Delivery. Todos los derechos reservados</p>
      </div>
    </FooterStyles>
  );
}
