import { CardForm } from "../../components/CardForm/CardForm";
import { CardSlider } from "../../components/CardSlider/CardSlider";
import { AliadosLoginStyles } from "./AliadosLoginStyles";

export function AliadosLogin() {
  return (
    <AliadosLoginStyles>
      <main className="lodin-container bd-container">
        <CardForm />
        <div className="login__slider">
          <CardSlider />
        </div>
      </main>
    </AliadosLoginStyles>
  );
}
