import styled from "styled-components";
export const NavbarStyles = styled.nav`
  .navbar {
    position: fixed;
    z-index: var(--z-fixed);
    display: flex;
    justify-content: center;
    width: 100%;
    height: 70px;
    margin-bottom: 30px;

    background-color: var(--main-color);

    transition: 0.5s;
    :hover {
      background-color: var(--main-color);
      -webkit-box-shadow: 6px 3px 16px -6px #000000;
      box-shadow: 6px 3px 16px -6px #000000;
    }
  }

  .active-nav {
    background-color: var(--main-color);
    -webkit-box-shadow: 6px 3px 16px -6px #000000;
    box-shadow: 6px 3px 16px -6px #000000;
  }

  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: auto;
  }
  .nav__logo {
    display: flex;
    align-items: center;
    width: 100px;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .nav__menu {
    height: 100%;
    display: flex;
    justify-content: space-between;
    list-style: none;
    background-color: var(--main-color);

    position: absolute;
    top: -79rem;
    left: 0;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition: 0.5s all ease;
    .nav__header-mobile {
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-direction: column;
      .nav__logo-mobile {
        display: initial;
        width: 150px;
        margin: 1.2rem;
      }
      .nav__icon-close {
        display: initial;
        align-self: flex-end;
        margin: 1rem;
      }
    }
  }
  .nav__menu.open__menu {
    width: 100%;
    top: 0px;

    bottom: 0;
    margin: 0;
  }
  .nav__item {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    a {
      text-decoration: none;
      color: white;
    }
  }
  .nav__link {
    border-radius: 0;
    padding: 0.5rem 1.2rem;
    margin-top: 30px;
  }
  .nav__icon-menu {
    display: flex;

    align-items: center;
    cursor: pointer;

    color: white;
  }

  /* @media screen and (min-width: 960px) {
    .navbar {
      background-color: transparent;
    }
    .nav__menu {
      position: initial;
      top: 0;
      left: initial;
      height: 100%;
      justify-content: space-between;
      list-style: none;
      flex-direction: row;
      background-color: var(--main-color);
      .nav__header-mobile {
        display: initial;
        justify-content: space-between;
        width: 100%;
        flex-direction: row;
        .nav__logo-mobile {
          display: none;
        }
        .nav__icon-close {
          display: none;
        }
      }
    }
    .nav__link {
      display: flex;
      justify-content: center;
      align-items: center;

      padding: 0.3rem 1.2rem;
      color: var(--font-color-2);
      border: solid 1px transparent;
      font-size: 1rem;
      font-weight: 300;
      cursor: pointer;
      border-radius: 6px;
      transition: 0.5s all ease;
    }
    .nav__icon-menu,
    .nav__icon-close,
    .nav__logo-mobile {
      display: none;
    }
  } */

  /* .nav__item {
    display: flex;
    height: 100%;
    align-items: center;
    margin-right: 1.5rem;
  }
  .nav__link {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0.3rem 1.2rem;
    color: var(--font-color-2);
    border: solid 1px transparent;
    font-size: 1rem;
    font-weight: 300;
    cursor: pointer;
    border-radius: 6px;
    transition: 0.5s all ease;
    &:hover {
      color: var(--white);
      background-color: var(--hover-color-3);
      border: solid 1px var(--white);
      border-radius: 6px;
    }
  }
  .active-link {
    color: var(--white);
  } */
  /* .nav__icon-menu,
  .nav__icon-close,
  .nav__logo-mobile {
    display: none;
  } */

  /* @media screen and (max-width: 960px) {
    .nav__menu {
      background-color: var(--tercer-color);
      position: absolute;
      top: -79rem;

      left: 0;

      width: 100%;
      height: 100vh;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      transition: 0.5s all ease;

      .nav__header-mobile {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .nav__logo-mobile {
          display: initial;
          width: 150px;
          margin: 1.2rem;
        }
        .nav__icon-close {
          display: initial;
          align-self: flex-end;
          margin: 1rem;
        }
      }
    }
    .nav__logo {
      width: 150px;
    }
    .nav__menu.open__menu {
      width: 100%;
      top: 0px;

      bottom: 0;
      margin: 0;
    }
    .nav__item {
      width: 100%;
      height: 70px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .nav__link {
      border-radius: 0;
      padding: 0.5rem 1.2rem;
      margin-top: 30px;
    }
    .nav__icon-menu {
      display: flex;

      align-items: center;
      cursor: pointer;

      color: white;
    }
  } */
`;
